body {
    padding: 0px;
    margin: 0px;
    height: 800px;
    flex-grow: 0;
    background: linear-gradient(to bottom, #ffffff, #e6ebf3);
    direction: rtl;
    font-family: Assistant;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
}

.main {
    padding: 0px;
    margin: 0px;
}

.center {
    text-align: center;
}

.center button {
    margin-right: auto;
    margin-left: auto;
}

.inner-page {
    padding: 20px;
    margin: 0px;
}

.inner-page h1 {
    padding: 0px;
    margin: 0px;
}

.header{
    width: 100%;
    height: 52px;
    margin: 0px 0px;
    padding: 8px 16px;
    background-image: linear-gradient(to bottom, #370f7e, #7d0581, #b81b8e);
    direction: ltr;
}

.tondoLogo {

    flex-grow: 0;
    margin: 6px;
    direction: ltr;
}

.cityLogo {
   direction: rtl;
}

.cityLogo img {
    width: 38px;
    height: 38px;
}

.page-content {
    margin-top: 12px;
    margin-right: 5px;
    margin-left: 5px;
}

.Title {
    font-family: Assistant;
    font-size: 28px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: right;
    color: #000;
}

.align-left {
    direction: ltr;
}

.time-remaining {
    padding: 50px;
    max-width: 500px;
}

.CircularProgressbar .CircularProgressbar-text {
    font-size: 16px;
}

.light-button {
    cursor: pointer;
    width: 204px;
    height: 55px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 12px;
    margin: 28px 78px 30px 30px;
    padding: 7px 22px;
    border-radius: 18px;
    background-color: #332a8f;
    color: #ffffff;
    font-weight: bold;
    font-size: 28px;
}

.light-button[disabled]{
   background-color: #cccccc;
}

.alert {
    color: #a82710;
    font-weight: bold;
    padding-top: 0px;
    padding-bottom: 0px;
    margin: 0px;
}